<template>
  <div class="wechat-container">
    <CSDialog
      :dialogTitle="disposeResTitle"
      :dialogVisible="orderContentVisible"
      dialogWidth="380px"
      @onClose="onCloseBtn"
      :showConfirmBtn="false"
      :dialogShowConfirmBtn="false"
      :dialogCancelBtnText="'关闭'"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div style="margin-bottom: 23px" class="img-dialog imgPan">
          <div
            class="qrcode"
            ref="qrCodeUrl"
            style="margin: 0 auto; width: 150px; height: 150px"
          ></div>
          <div class="panl" v-if="successNumber == 1">
            <img src="../assets/zhifuwancheng.png" />
            <span>已支付</span>
          </div>
        </div>
        <div class="text-zip" @click="downloadImage">下载</div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import {
  createOrderUtillUrl,
  propertyBillPcPayUrl,
  queryBillStateIsUrl,
} from "@/requestUrl";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      orderContentVisible: false,
      disposeResTitle: "微信支付",
      id: "",
      infoList: [], //信息列表
      code_url: "", //二维码地址
      qrcode: "",
      wechatIs: 0,
      timeInter: "", //计时器
      successNumber: 0, //是否支付完成
    };
  },
  mounted() {
    this.$vc.on(this.$route.path, "WechatPayment", "show", (item) => {
      this.orderContentVisible = true;
      this.id = item.value.id;
      this.wechatIs = item.state;
      this.queryInfo();
    });
  },
  components: {
    CSDialog,
  },
  methods: {
    creatQrCode(item) {
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: item, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(this.qrcode);
    },
    //下载按钮
    downloadImage() {
      let imgUrl = document.querySelector(".qrcode img");

      const link = document.createElement("a");
      const body = document.querySelector("body");
      link.src = imgUrl.src;
      link.href = imgUrl.src; // 创建对象url
      link.download = "支付码";

      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href); // 通过调
    },
    //获取二维码图片
    queryImage(infoList) {
      this.$fly
        .post(createOrderUtillUrl, {
          ...infoList,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.isQuery(this.id);
          this.creatQrCode(JSON.parse(res.data).code_url);
          this.code_url = JSON.parse(res.data).code_url;
        });
    },
    //获取支付信息
    queryInfo() {
      this.$fly
        .post(propertyBillPcPayUrl, {
          id: this.id,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.infoList = res.data;
          //获取二维码图片信息
          this.queryImage(this.infoList);
        });
    },
    //关闭按钮
    onCloseBtn() {
      clearInterval(this.timeInter);
      this.orderContentVisible = false;
    },
    isQuery(idR) {
      clearInterval(this.timeInter);
      this.timeInter = setInterval(() => {
        this.$fly
          .get(queryBillStateIsUrl, {
            id: idR,
          })
          .then((res) => {
            if (res.data == 1) {
              this.successNumber = 1;
              this.$vc.emit(this.$route.path, "successOff", {});
              clearInterval(this.timeInter);
            }
          });
      }, 1000);
    },
  },
};
</script>

<style scoped>
.imgPan {
  position: relative;
}
.img-dialog img {
  width: 100%;
}
.text-zip {
  text-align: center;
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 auto;
  width: 48px;
}
.panl {
  width: 300px;
  height: 160px;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  top: -5px;
}
.panl img {
  width: 60px;
  height: 60px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 40px);
}
.panl span {
  font-size: 28px;
  color: #00b694;
  font-weight: 800;
  position: absolute;
  left: calc(50% - 30px);
  bottom: 0;
}
</style>